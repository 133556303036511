import React, {FC, useEffect, useRef, useState} from "react";
import {Breadcrumb, Button, Col, Container, Row} from "react-bootstrap";
import {ConfirmModal, Footer, FormCol, FormText, FormTextArea, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate, useParams} from "react-router";
import {useImmer} from "use-immer";
import {Helmet} from "react-helmet";
import {useTRPC} from "backoffice/ui/App";
import {Currency} from "src/codes";
import {FCBank} from "src/rds/types/sociallending/FCBank";
import {useImmerChangeHandler} from "backoffice/ui/utils/react/hooks.tsx";


export const MembersFCBankFormPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();

  const memoRef = useRef<HTMLTextAreaElement>(null);

  const [model, setModel]                  = useImmer<FCBank | null>(null),
        [{checking, confirming}, setState] = useState({checking: false, confirming: false}),
        [modal, setModal]                  = useState('');


  const handleChange = useImmerChangeHandler(setModel);

  const {id} = useParams();

  // 初回実行
  useEffect(() => {

    if (!id) {
      Navigate('../');
      return;
    }

    Loader.task(() => tRPC.member.fcbank.get.query({id}))
          .then(({model, ...state}) => {
            setModel(model);
            setState(state);
          })
          .catch(err => {
            console.error(err);
            Message.error(err, () => Navigate('../'));
          });
  }, [id]);

  /**
   * 保存
   */
  const handleChecked = (status: string) => {
    if (model === null || !checking) {
      return;
    }

    Loader.task(() => tRPC.member.fcbank.check.mutate({...model, status}), 500)
          .then(() => Message.show('確認登録が完了しました', () => location.reload()))
          .catch(err => {
            console.error(err);
            Message.error(err);
          });
  }

  const handleConfirmed = () => {
    if (model === null || !confirming) {
      return;
    }


    Loader.task(() => tRPC.member.fcbank.confirm.mutate(model), 500)
          .then(() => Message.show('承認登録が完了しました', () => location.reload()))
          .catch(err => {
            console.error(err);
            Message.error(err);
          });
  }


  return (
      <>
        <Helmet>
          <title>外貨受取 詳細確認 #{id}</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>お客様管理</Breadcrumb.Item>
            <Breadcrumb.Item href={'./'}>外貨受取方法申請</Breadcrumb.Item>
            <Breadcrumb.Item active>詳細確認 #{id}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className={'container'}>

          <Container className={'my-5'}>
            <Row>
              <Col xl={{offset: 2, span: 8}}>


                <Container className={'my-4'}>
                  <Row>
                    <FormCol inputId={'user_id'} className={'py-1'}
                             title={'ユーザID'}
                             size={{xs: [4, 8], md: [2, 4]}}>

                              <span className={'form-control'}>
                                {model?.user_id}
                              </span>
                    </FormCol>
                  </Row>

                  <Row>
                    <FormCol inputId={'currency_id'} className={'py-1'}
                             title={'通貨'}
                             size={{xs: [4, 8], md: [2, 4]}}>

                              <span className={'form-control'}>
                                {Currency.find(model?.currency_id)?.value}
                              </span>
                    </FormCol>
                  </Row>

                  <Row>
                    <FormCol inputId={'bank_name'} className={'py-1'}
                             title={'銀行名称'}
                             size={{xs: [4, 8], md: [2, 8]}}>

                      <FormText name={'bank_name'}
                                value={model?.bank_name ?? ''}
                                onChange={handleChange}
                                readOnly={!checking}/>
                    </FormCol>
                  </Row>

                  <Row>
                    <FormCol inputId={'branch_name'} className={'py-1'}
                             title={'支店名称'}
                             size={{xs: [4, 8], md: [2, 8]}}>

                      <FormText name={'branch_name'}
                                value={model?.branch_name ?? ''}
                                onChange={handleChange}
                                readOnly={!checking}/>
                    </FormCol>
                  </Row>

                  <Row>
                    <FormCol inputId={'swift_code'} className={'py-1'}
                             title={'SWIFTコード'}
                             size={{xs: [4, 4], md: [2, 2]}}>

                      <FormText name={'swift_code'}
                                value={model?.swift_code ?? ''}
                                onChange={handleChange}
                                readOnly={!checking}/>
                    </FormCol>
                  </Row>

                  <Row>
                    <FormCol inputId={'address'} className={'py-1'}
                             title={'支払銀行住所'}
                             size={{xs: [4, 8], md: [2, 10]}}>

                      <FormText name={'address'}
                                value={model?.address ?? ''}
                                onChange={handleChange}
                                readOnly={!checking}/>
                    </FormCol>
                  </Row>

                  <Row>
                    <FormCol inputId={'account_number'} className={'py-1'}
                             title={'口座番号'}
                             size={{xs: [4, 8], md: [2, 2]}}>

                      <FormText name={'account_number'}
                                value={model?.account_number ?? ''}
                                onChange={handleChange}
                                readOnly={!checking}/>
                    </FormCol>
                  </Row>

                  <Row>
                    <FormCol inputId={'account_name'} className={'py-1'}
                             title={'口座名義人'}
                             size={{xs: [4, 8], md: [2, 4]}}>

                      <FormText name={'account_name'}
                                value={model?.account_name ?? ''}
                                onChange={handleChange}
                                readOnly={!checking}/>
                    </FormCol>
                  </Row>

                  <Row>
                    <FormCol inputId={'account_address'} className={'py-1'}
                             title={'口座名義人住所'}
                             size={{xs: [4, 8], md: [2, 10]}}>

                      <FormText name={'account_address'}
                                value={model?.account_address ?? ''}
                                onChange={handleChange}
                                readOnly={!checking}/>
                    </FormCol>
                  </Row>

                  {confirming && (
                      <Row>
                        <FormCol inputId={'status'} className={'py-1'}
                                 title={'登録状況'}
                                 size={{xs: [4, 8], md: [2, 4]}}>

                              <span className={'form-control text-danger'}>
                                {model?.status}
                              </span>
                        </FormCol>
                      </Row>
                  )}
                  <Row>
                    <FormCol inputId={'note'} className={'py-1'}
                             title={'摘要'}
                             size={{xs: [4, 8], md: [2, 10]}}>

                      <FormTextArea name={'note'}
                                    value={model?.note ?? ''}
                                    onChange={handleChange}
                                    readOnly={!checking}/>
                    </FormCol>
                  </Row>

                </Container>

              </Col>
            </Row>
          </Container>
        </div>

        {/* フッター */}
        <Footer>
          {checking && (
              <>
                <Button variant='primary'
                        className={'m-1'}
                        style={{width: 100}}
                        onClick={() => setModal('OK')}>
                  OK登録
                </Button>
                <Button variant='danger'
                        className={'m-1'}
                        style={{width: 100}}
                        onClick={() => setModal('NG')}>
                  NG登録
                </Button>
              </>
          )}

          {confirming && (
              <Button variant={'secondary'}
                      className={'ms-4 m-1'}
                      style={{width: 100}}
                      onClick={() => setModal('confirm')}>
                承認する
              </Button>
          )}
        </Footer>

        <ConfirmModal show={modal === 'OK'}
                      confirmLabel={'登録する'}
                      onConfirm={() => {
                        handleChecked('OK');
                        setModal('');
                      }}
                      onCancel={() => setModal('')}>

          口座情報を「OK」として登録しますか？
        </ConfirmModal>

        <ConfirmModal show={modal === 'NG'}
                      confirmLabel={'登録する'}
                      onConfirm={() => {
                        handleChecked('NG');
                        setModal('');
                      }}
                      onCancel={() => setModal('')}>

          口座情報を「NG」として登録しますか？
        </ConfirmModal>

        <ConfirmModal show={modal === 'confirm'}
                      confirmLabel={'承認する'}
                      onConfirm={() => {
                        handleConfirmed();
                        setModal('');
                      }}
                      onCancel={() => setModal('')}>

          口座情報を「{model?.status}」として承認しますか？
        </ConfirmModal>
      </>
  );
}
