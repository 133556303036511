import React, {FC, useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {Breadcrumb, Button, Col, Container, Form, Row, Table} from "react-bootstrap";
import {FormCol, FormSelect, FormText, Pager, useLoader, useMessage} from "backoffice/ui/Components";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import {useImmer} from "use-immer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {useTRPC} from "backoffice/ui/App";
import {AppRouterOutput} from "backoffice/api/AppRouter";
import {userConv} from "src/utils/conv";

const defaultCondition = {
  status : '',
  step   : 'not-yet',
  user_id: '',
}

export const MembersFCBankListPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();

  // ステート
  const [query, setQuery]         = useSearchParams(),
        [condition, setCondition] = useImmer(defaultCondition),
        [result, setResult]       = useState({total: 0, list: []} as AppRouterOutput['member']['fcbank']['list']);


  // 初期化
  useEffect(() => {

    const cond = {
            status : query.get('status') || defaultCondition.status,
            step   : query.get('step') || defaultCondition.step,
            user_id: query.get('user_id') || defaultCondition.user_id,
          },
          page = Number(query.get('page')) || 1;


    setCondition(cond);


    // データ取得
    (async () => {
      const req = {...cond, page};
      await Loader.task(async () => await tRPC.member.fcbank.list.query(req), 300)
                  .then(res => setResult(res))
                  .catch(err => {
                    console.error(err);
                    Message.error(err);
                  });
    })();
  }, [query]);


  // 検索
  const handleSearch = () => {
    for (const [k, v] of Object.entries(condition)) {
      if (v.trim()) {
        query.set(k, v);
      } else {
        query.delete(k);
      }
    }
    handlePage(1);
  };

  // ページ遷移
  const handlePage = (page: number) => {
    query.set('page', String(page));
    query.set('ts', String(new Date().getTime()));
    setQuery(query);
  }


  return (
      <>
        <Helmet>
          <title>外貨受取方法申請</title>
        </Helmet>

        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>お客様管理</Breadcrumb.Item>
            <Breadcrumb.Item active>外貨受取方法申請</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container>
          <Row>
            <Col xl={{offset: 2, span: 8}}>
              <Form className={'my-5'}>
                <input type='submit' className={'d-none'} disabled/>
                <Row>
                  <FormCol inputId={'status'} className={'py-1'}
                           title={'結果'}
                           size={{xs: [4, 8], md: [2, 4]}}>
                    <FormSelect value={condition.status}
                                onChange={({target: {value}}) => setCondition(draft => void (draft.status = value))}>
                      <option value={''}>-</option>
                      <option value={'request'}>申請中</option>
                      <option value={'OK'}>OK</option>
                      <option value={'NG'}>NG</option>
                    </FormSelect>
                  </FormCol>

                  <FormCol inputId={'step'} className={'py-1'}
                           title={'進捗状況'}
                           size={{xs: [4, 8], md: [2, 4]}}>
                    <FormSelect value={condition.step}
                                onChange={({target: {value}}) => setCondition(draft => void (draft.step = value))}>
                      <option value='not-yet'>未チェック</option>
                      <option value='checked'>確認済み</option>
                      <option value='confirmed'>承認済み</option>
                    </FormSelect>
                  </FormCol>
                </Row>

                <Row>
                  <FormCol inputId={'step'} className={'py-1'}
                           title={'ユーザーID'}
                           size={{xs: [4, 8], md: [2, 4]}}>

                    <FormText name="user_id" type="text" placeholder="ユーザーID"
                              onChange={({target: {value}}) => setCondition(draft => void (draft.user_id = value))}/>
                  </FormCol>
                </Row>


                <Row style={{marginTop: 20}}>
                  <Col md={{span: 6, offset: 3}} style={{padding: 10}}>
                    <Button variant="primary" className={'w-100'}
                            onClick={handleSearch}>
                      検索する
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>

        <div className={'container small'}>
          <Table hover>
            <thead>
            <tr>
              <th className={'text-end'}>ID</th>
              <th className={'text-center'} style={{width: '6rem'}}>ユーザーID</th>
              <th className={'text-center'} style={{width: '5rem'}}>受取方法</th>
              <th className={'text-center'} style={{width: '4rem'}}>結果</th>
              <th className={'text-center'} style={{width: '6rem'}}>進捗</th>
              <th className={'text-center'} style={{width: '6rem'}}>申請日時</th>
              <th className={'text-center'} style={{width: '6rem'}}>判定者</th>
              <th className={'text-center'} style={{width: '12rem'}}>判定日時</th>
              <th className={'text-center'} style={{width: '6rem'}}>承認者</th>
              <th className={'text-center'} style={{width: '12rem'}}>承認日時</th>
              <th style={{width: '3rem'}}>&nbsp;</th>
            </tr>
            </thead>

            <tbody>
            {/* データなし */}
            {result.list.length === 0 && (
                <tr>
                  <td colSpan={11}>---</td>
                </tr>
            )}

            {result.list.map((elem, index) => {
                  const step = elem.method === 'exchange' ? '承認済み' :
                               !!elem.confirmed_at ? '承認済み' :
                               !!elem.checked_at ? '確認済み' :
                               '未確認';

                  return (
                      <tr key={elem.id} className={(index % 2) ? 'bg-white' : 'bg-light'}>
                        <td className={'p-1 text-end    align-middle'}>{elem.id}</td>
                        <td className={'p-1 text-center align-middle'}>{elem.user_id}</td>
                        <td className={'p-1 text-center align-middle'}>{elem.method === 'exchange' ? '両替' : '出金'}</td>
                        <td className={'p-1 text-center align-middle'}>{elem.status}</td>
                        <td className={'p-1 text-center align-middle'}>{step}</td>
                        <td className={'p-1 text-center align-middle'}>{elem.updated_at}</td>
                        <td className={'p-1 text-center align-middle'}>{userConv(elem.checked_by) || '---'}</td>
                        <td className={'p-1 text-center align-middle'}>{elem.checked_at}</td>
                        <td className={'p-1 text-center align-middle'}>{userConv(elem.confirmed_by) || '---'}</td>
                        <td className={'p-1 text-center align-middle'}>{elem.confirmed_at}</td>
                        <td className={'p-1 text-center align-middle'}>
                          <Link to={`./${elem.id}`} className={'btn btn-primary btn-sm'}>
                            <FontAwesomeIcon icon={faEdit}/>
                          </Link>
                        </td>
                      </tr>
                  )
                }
            )}
            </tbody>
          </Table>

          <div className={'d-flex justify-content-between'}>
            <span>件数: {String(result.total).withComma}</span>
            <Pager activePage={Number(query.get('page')) || 1}
                   pageSize={50}
                   totalCount={result.total}
                   displaySize={2}
                   onChange={({page}) => handlePage(page)}/>
          </div>
        </div>
      </>
  );
}
