import {FC} from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {Link} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding} from "@fortawesome/free-solid-svg-icons";
import {useUser} from "backoffice/ui/App";
import {DropDirection} from 'react-bootstrap/esm/DropdownContext';


type MenuItem = MenuGroup | MenuLink | MenuDivider

interface MenuGroup {
  title: string,
  submenu: MenuItem[]
}

interface MenuLink {
  title: string,
  href: string,
}

interface MenuDivider {
  divider: true
}

const Divider: MenuDivider = {divider: true};

const Menu: MenuGroup[] = [
  {
    title  : '融資管理',
    submenu: [
      {title: '融資先一覧', href: '/loan-manager/customer'},
      {title: '融資案件一覧', href: '/loan-manager/project'},
      {title: '両替一覧', href: '/loan-manager/exchange'},
      {title: '取引一覧', href: '/loan-manager/trade'},
      Divider,
      {title: '融資案件リスト（レガシー）', href: '/legacy?path=loanmgr/projects'},
      {title: '取引一覧（レガシー）', href: '/loan-manager/legacy-trade'},
      {title: 'アロケーション確認', href: '/legacy?path=loanmgr/check'},
      {title: '乖離確認', href: '/legacy?path=loanmgr/check/disparity'},
      {title: '資金履歴', href: '/legacy?path=loanmgr/finance'},
      {title: '分配・償還 データ管理', href: '/legacy?path=loanmgr/dividends'},
      {title: '利息内訳', href: '/legacy?path=loanmgr/breakdown'},
      {title: '返済予定', href: '/legacy?path=loanmgr/repaymentschedule'},
    ]
  },
  {
    title  : 'ファンド管理',
    submenu: [
      {title: 'ファンドリスト', href: '/legacy?path=funds'},
      {title: 'ファンド記事リスト', href: '/legacy?path=funds/articles'},
      {title: 'ファンドモニター', href: '/legacy?path=itemmonitor'},
      {title: 'アロケーション確認', href: '/legacy?path=item/check'},
      {title: '超過確認', href: '/legacy?path=item/check/over'},
      {title: '資金履歴', href: '/legacy?path=item/finance'},
      {title: '分配計算', href: '/legacy?path=funds/dividends'},
      {title: '運用報告書リスト', href: '/legacy?path=item/fundreport'},
      {title: '運用報告書リストNew', href: '/legacy?path=funds/report'},
    ]
  },
  {
    title  : '資金管理',
    submenu: [
      {title: '出金管理', href: '/legacy?path=withdraw'},
      {title: '資金移動管理', href: '/legacy?path=banking'},
      {title: '両替管理', href: '/legacy?path=accounting/exchange'},
      {title: 'みずほ 米ドルレート', href: '/legacy?path=forex?symbol=MIZUHO-USD'},
      {title: 'みずほ 豪ドルレート', href: '/legacy?path=forex?symbol=MIZUHO-AUD'},
      {title: 'みずほ レート管理New', href: '/legacy?path=accounting/mizuhorate'},
      {title: '日差表データ確認', href: '/legacy?path=accounting/dailycheck'},
    ],
  },
  {
    title  : 'お客様管理',
    submenu: [
      {title: '商品取引履歴(新)', href: '/members/trades'},
      {
        title  : 'Push通知管理',
        submenu: [
          {title: '紐付け状況一覧', href: '/members/noticemng/devices'},
          {title: '配信予約登録', href: '/members/noticemng/notifications/new'},
          {title: '配信状況一覧', href: '/members/noticemng/notifications'}
        ]
      },
      Divider,
      {
        title  : '会員情報',
        submenu: [
          {title: '会員一覧', href: '/legacy?path=member/'},
          {title: '待ち一覧', href: '/legacy?path=member/approval'},
          {title: '名寄せ管理', href: '/legacy?path=members/idchecks'},
          {title: '完了通知 追跡管理', href: '/legacy?path=members/postkyc'},
          {title: '変更履歴一覧', href: '/legacy?path=member/history'},
          {title: 'PDF Upload', href: '/legacy?path=member/upload'},
          {title: '本人確認書類', href: '/legacy?path=members/uploadImage'},
          {title: 'App Access Token', href: '/legacy?path=accessToken'},
        ]
      },
      {title: '仮想口座割当', href: '/legacy?path=virtualaccount'},
      {title: '問い合わせ管理', href: '/legacy?path=inquiries'},
      {title: 'カスタマーメール', href: '/legacy?path=customermails'},
      {title: '商品取引履歴', href: '/legacy?path=trade'},
      {title: '顧客勘定元帳', href: '/legacy?path=tradehistory'},
      {title: '取引日記帳兼募集等の取扱いの取引記録', href: '/legacy?path=tradediary'},
      {
        title  : 'マーケティングメール',
        submenu: [
          {title: 'フロー確認', href: '/legacy?path=sendmail/flows'},
          {title: 'テンプレ－ト', href: '/legacy?path=sendmail/templates'},
          {title: '送信タスク', href: '/legacy?path=sendmail/tasks'},
        ]
      },
      {title: '仮想口座入金', href: '/members/deposit'},
      {title: '投資申請一覧', href: '/legacy?path=members/investapplications'},
      {title: 'データ抽出', href: '/legacy?path=data'},
      {title: '楽天ポイント', href: '/legacy?path=rakutenpoint'},
      {title: '外貨受取方法申請', href: '/members/fcbank'},
    ]
  },
  {
    title  : 'データ確認',
    submenu: [
      {
        title  : 'SQLView',
        submenu: [
          {title: 'カスタマー', href: '/sql-view/customer'},
        ]
      },
      Divider,
      {title: '日次データ', href: '/legacy?path=info/daily'},
      {title: '月次データ', href: '/legacy?path=info/monthly'},
      {title: 'ドルデータ', href: '/legacy?path=info/usd'},
      {title: '外貨データ', href: '/legacy?path=info/foreigncurrency'},
      {title: '社内ディスプレイ表示', href: '/legacy?path=info/display'},
      {title: 'SQL View（旧）', href: '/legacy?path=info/sql'},
    ]
  },
  {
    title  : '地金取引',
    submenu: [
      {title: '価格一覧', href: '/legacy?path=material/price'},
      {title: '注文一覧', href: '/legacy?path=material/trade'},
      {
        title  : '積立取引',
        submenu: [
          {title: '登録口座一覧', href: '/legacy?path=material/reserving/account'},
          {title: '口座登録リクエスト（電文）', href: '/legacy?path=material/reserving/account/request'},
          {title: '口座登録結果', href: '/legacy?path=material/reserving/account/result'},
          {title: '口座振替一覧', href: '/legacy?path=material/reserving/transaction'}
        ]
      },
      {
        title  : 'タスク一覧',
        submenu: [
          {title: 'すべて', href: '/legacy?path=material/task'},
          {title: 'CB 経理Div', href: '/legacy?path=material/task/cb'},
          {title: 'CB コモディティDiv', href: '/legacy?path=material/task/cd'},
          {title: '第一商品', href: '/legacy?path=material/task/d1'},
        ]
      },
      {title: '第一商品カレンダー', href: '/legacy?path=calendar/edit/101'},
      {title: '重量確認 / SQLView', href: '/legacy?path=info/sql?script=%2F03+地金取引%2F01+金+合計重量.yaml'},
      {title: '顧客リスト / SQLView', href: '/legacy?path=info/sql?script=%2F03+地金取引%2F02+金+顧客リスト.yaml'}
    ],
  },
  {
    title  : '会計モジュール',
    submenu: [
      {title: '仕訳', href: '/accounting/journal'},
    ]
  },
  {
    title  : 'その他',
    submenu: [
      {title: 'ユーザー管理', href: '/users'},
      {title: 'ロール管理', href: '/users/roles'},
      Divider,
      {title: 'GMOあおぞら銀行API', href: '/banking/gmo-aozora-net'},
      Divider,
      {title: 'ユーザー管理（旧）', href: '/legacy?path=user'},
      {title: '郵便番号管理', href: '/legacy?path=postalcode'},
      {title: 'ユーザーログ', href: '/legacy?path=user/log'},
      {title: '広告管理', href: '/legacy?path=adinfo'},
      {title: '為替基準レート', href: '/legacy?path=bank/rate'},
    ]
  }
];

// Component
export const Header: FC = () => {
  const user = useUser();
  return (
      <header className={'fixed-top'}>
        <Navbar bg="light" collapseOnSelect className={'small px-3'}>
          <Navbar.Toggle aria-controls='basic-navbar-nav'/>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Navbar.Brand as={Link} to='/'>
              <FontAwesomeIcon icon={faBuilding}/>
              <small className={'ps-1'}>
                Backoffice
              </small>
            </Navbar.Brand>
            <Nav className={'me-auto'} as={'ul'}>
              {Menu.map((item, index) => {
                const key = `menu-${index}`;
                return (<MenuItem item={item} key={key} subKey={key}/>)
              })}
              <Navbar.Text as={Link} to='/legacy' className={'ms-2'}>旧Verへ</Navbar.Text>
            </Nav>
            <Nav>
              <NavDropdown title={user.name ?? user.email ?? 'unknown'} align={'end'}>
                <NavDropdown.Item href='/logout'>ログアウト</NavDropdown.Item>
                <NavDropdown.Divider/>
                <NavDropdown title={"テスト"} drop={'start'} className={'px-2'}>
                  {/*<NavDropdown.Item href={'/test/sqs-mail'}>SQSMail</NavDropdown.Item>*/}
                  <NavDropdown.Item href={'/test/uiux/numbers'}>数値入力</NavDropdown.Item>
                </NavDropdown>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
  );
}


const MenuItem: FC<{ item: MenuItem, subKey: string, drop?: DropDirection }> = ({item, subKey, drop = 'down'}) => {
  if ('submenu' in item) {
    return (
        <NavDropdown drop={drop} title={item.title} className={'px-2'}>
          {item.submenu.map((subItem, subIndex) => {
            const key = `${subKey}-${subIndex}`;
            return <MenuItem item={subItem} key={key} subKey={key} drop={'end'}/>
          })}
        </NavDropdown>
    );
  } else if ('title' in item) {
    return (
        <NavDropdown.Item as={Link} to={item.href}>
          {item.title}
        </NavDropdown.Item>
    )
  } else {
    return <NavDropdown.Divider/>
  }
}
