import {AbstractCode, CodeType} from "src/codes/abstract";

export interface FunctionCodeType extends CodeType<string> {
  actions: string[],
}

export const FunctionCode = new AbstractCode<FunctionCodeType>([
  {
    id     : 'users',
    value  : 'ユーザーリスト',
    actions: [
      'write',
      'delete',
    ]
  },
  {
    id     : 'loan-manager.customer',
    value  : '融資管理 取引先',
    actions: [
      'read',
      'write',
    ]
  },

  {
    id     : 'loan-manager.project',
    value  : '融資管理 融資案件',
    actions: [
      'read',
      'write',
    ]
  },

  {
    id     : 'loan-manager.allocation',
    value  : '融資管理 アロケーション',
    actions: [
      'read',
      'write',
    ]
  },

  {
    id     : 'loan-manager.trade',
    value  : '融資管理 取引情報',
    actions: [
      'read',
      'write',
      'confirm',
      'rollback'
    ]
  },

  {
    id     : 'loan-manager.exchange',
    value  : '融資管理 両替情報',
    actions: [
      'read',
      'write',
      'execute',
      'confirm'
    ]
  },

  {
    id     : 'sql-view',
    value  : 'SQLView',
    actions: [
      'customer',
    ]
  },
  {
    id     : 'banking.gmo-aozora-net',
    value  : 'GMOあおぞら銀行設定',
    actions: [
      'setting',
    ]
  },
  {
    id     : 'member.fcbank',
    value  : 'お客様管理 外貨出金先口座',
    actions: [
      'read',
      'write',
      'confirm',
    ]
  },
  {
    id     : 'member.trade',
    value  : 'お客様管理 商品取引履歴',
    actions: [
      'read',
      'write'
    ]
  },
  {
    id     : 'member.deposit',
    value  : 'お客様管理 仮想口座入金',
    actions: [
      'update',
      'confirm',
    ]
  },
  {
    id     : 'noticemng',
    value  : 'Push通知管理',
    actions: [
      'device.read',
      'device.csv',
      'notification.read',
      'notification.write',
      'notification.csv',
    ]
  }
])
