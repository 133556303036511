import React, {FC,} from "react";
import {Route} from "react-router-dom";
import {Navigate, Routes} from "react-router";
import {MembersFCBankListPage} from "./List";
import {MembersFCBankFormPage} from "./Form";

export const MembersFCBankPage: FC = () => {
  return (
      <Routes>
        <Route index element={<MembersFCBankListPage/>}/>
        <Route path=":id" element={<MembersFCBankFormPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
  );
}
